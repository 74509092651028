<template>
    <div class="form-input" :class="{'has-error': error}">
        <input type="hidden" :name="name" :value="value">
        <span class="custom-select-v2-label" :class="{required: required}">{{label}}</span>
        <v-select
            :options="options"
            class="custom-select-v2"
            :name="name"
            :components="{OpenIndicator}"
            :clearable="false"
            :searchable="searchable ? searchable : false"
            :noDrop="countOfNoDrop ? isNoDrop : false"
            :multiple="multiple ? multiple : false"
            :reduce="item => item.value"
            :value="value"
            :placeholder="placeholder"
            @input="update"
            @search="onSearch"
        >
<!--            <div slot="no-options" v-if="noOptions">{{noOptions}}</div>-->
          <span slot="no-options">Нічого не знайдено</span>
        </v-select>
        <span class="error-label" v-if="error">{{error}}</span>
    </div>
</template>

<script>
import vSelect from "vue-select";
export default {
    name: "CustomSelectV2",
    props: ['options', 'label', 'name', 'value', 'error', 'placeholder', 'countOfNoDrop','searchable', 'multiple', 'noOptions', 'required'],
    components: {
        vSelect
    },
    data() {
        return {
            OpenIndicator: {
                render: createElement => createElement('span', {class: {'toggle': true}})
            },
            isNoDrop:true,
        }
    },
    methods: {
        update(id) {
            this.$emit("input", id);
        },
        onSearch(search, loading) {
            this.$emit("onSearch", search);
            this.isNoDrop = !(this.countOfNoDrop > 0 && search.length > this.countOfNoDrop);
        }
    }
}
</script>
<style lang="less">
.custom-select-v2-label{
    font-weight: 400;
    font-size: 12px;
    line-height: 1.33;
    color: @blueColor;
    display: block;
    margin-bottom: 6px;
    padding-left: 20px;
    &.required{
        &::before {
            content: "*";
            margin-right: 5px;
            margin-left: -13px;
            width: 5px;
            height: 5px;
            color: red;
        }
    }
}
.custom-select-v2{
    .vs__selected-options{
        height: 50px;
        padding: 0;
        width: calc(100% - 56px);
        @media all and(max-width: 480px) {
            height: 46px;
        }
    }
    .vs__selected{
        height: 100%;
        padding-left: 20px;
        margin: 0;
        color: @blueColor;
        width: 100%;
        font-size: 16px;
        font-weight: 500;

        white-space: nowrap;
        overflow: hidden;
        opacity: 1!important;
    }
    .vs__actions{
        padding: 0;
    }
    .vs__search{
        padding: 0 20px;
    }
    .vs__open-indicator{
        width: 56px;
        height: 100%;
        display: inline-block;
        position: relative;
        transform: none;
        padding: 8px 0;
        &:before{
            content: "";
            width: 1px;
            display: block;
            height: 100%;
            background: #F5F5F5;
        }
        &:after{
            position: absolute;
            content: "";
            top: 50%;
            margin-top: -10px;
            left: 50%;
            margin-left: -6px;
            width: 12px;
            height: 20px;
            background: url(../../assets/images/select.svg) no-repeat center;
            background-size: contain;
        }
    }
    .vs__dropdown-option{
        &--selected, &:hover{
            background-color: #FFC900!important;
            color: #fff!important;
        }
        background-color: #fff!important;
        color: #1A2744!important;
        //vs__dropdown-option--selected
    }
    //.vs__search{
    //    display: none;
    //}
    &.vs--open{
        .vs__dropdown-toggle{
            border-radius: 10px 10px 0 0;
            transition: border-color .15s linear;
            border: 1px solid @yellowColor;
        }
        //.vs__search{
        //    display: block;
        //}
    }
    .vs__dropdown-toggle{
        border: 1px solid #cdd4e0;
        border-radius: 10px;
        padding-bottom: 0;
        background-color: #fff;
        overflow-y: hidden;
        *{
            cursor: pointer;
        }
        @media all and(max-width: 660px) {
            width: 278px;
            min-width: 100%;
        }
    }
    .vs__dropdown-menu{
        border: 1px solid @yellowColor;
        border-top: 0;
        border-radius: 0 0 10px 10px;
    }
    .vs__fade-enter-active,
    .vs__fade-leave-active {
        transition: none;
    }
}
.form-input{
    position: relative;
    .error-label{
        position: absolute;
        top: 100%;
        right: 0;
        color: @yellowColor;
        font-size: 12px;
        text-align: right;
    }
    &.has-error{
        .vs__dropdown-toggle{
            border-color: @yellowColor;
        }
    }
}
.v-select .vs__dropdown-option{
    white-space: normal;
}
</style>
